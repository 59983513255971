import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonContainer } from '../Button';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { inViewTriggerThresholds } from '../../animationGlobals';

const DemoBanner = ({ title, ctaText, ctaLink, theme }) => {
  const sectionRef = useRef();
  const titleRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    const sectionEl = sectionRef.current;
    const titleEl = titleRef.current;
    const buttonEl = buttonRef.current;

    const toCommon = {
      duration: 0.4,
      ease: 'power4.out',
    };

    const delayCommon = `<${toCommon.duration * 0.1}`;

    const InFromBottom = [
      {
        opacity: 0,
        y: '25%',
      },
      {
        opacity: 1,
        y: '0%',
        ...toCommon,
      },
      delayCommon,
    ];

    const animation = gsap
      .timeline({})
      .fromTo(titleEl, ...InFromBottom)
      .fromTo(buttonEl, ...InFromBottom);

    const trigger = ScrollTrigger.create({
      trigger: sectionEl,
      ...inViewTriggerThresholds,
      animation: animation,
    });

    return () => {
      animation.progress(1);
      animation.kill();
      trigger.kill();
    };
  }, []);

  return (
    <section ref={sectionRef} className={`demo-banner demo-banner--${theme}`}>
      <div className="demo-banner__inner">
        <h2 ref={titleRef} className="demo-banner__title">
          {title}
        </h2>
        <ButtonContainer ref={buttonRef} align="center">
          <Button theme={'dark'} href={ctaLink}>
            {ctaText}
          </Button>
        </ButtonContainer>
      </div>
    </section>
  );
};

DemoBanner.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light', 'yellow']),
};

DemoBanner.defaultProps = {
  theme: 'light',
  ctaLink: '/demo',
};

export default DemoBanner;
